<template>
  <div>
    <portal to="page-top-title">Label #{{ id }}</portal>
    <portal to="page-top-right">
      <btn :route="{ name: 'campaigns-labels-edit', params: { id: id } }" icon="fa-edit" text>Edit</btn>
    </portal>

    <box-single :url="`/campaigns/labels/${id}`" data-prop="label" resp-prop="data" loader>
      <template slot="content">
        <row>
          <column-info title="Name">{{ label.name }}</column-info>
          <column-info title="Active">
            <badge-active :value="label.active"/>
          </column-info>
          <column-info title="Created At">{{ label.created_at }}</column-info>
          <column-info title="Updated At">{{ label.updated_at }}</column-info>
        </row>
        <row>
          <column-info title="Description" :sm="12" :md="12">{{ label.description }}</column-info>
        </row>
        <row>
          <column-info title="Campaigns">
            <badge title="Total">{{ label.campaigns_count }}</badge>
          </column-info>
        </row>
      </template>
    </box-single>
  </div>
</template>

<script>
import BoxSingle from '@/views/components/data/BoxSingle'
import Btn from '@/views/components/simple/Btn'
import BadgeActive from '@/views/components/simple/BadgeActive'
import Badge from '@/views/components/simple/Badge'

export default {
  name: 'CampaignsLabelsSingle',
  metaInfo () {
    return { title: 'Campaigns | Label #' + this.id }
  },
  components: {
    BoxSingle,
    Btn,
    BadgeActive,
    Badge
  },
  computed: {
    id () { return this.$route.params.id }
  },
  data () {
    return {
      label: {}
    }
  }
}
</script>
